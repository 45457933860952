.app-result {
    position: relative;
    padding-top: 120px;
    padding-bottom: 140px;
    background-color: $main-black;

    @include media-breakpoint-down(sm) {
        padding-bottom: 72px;
    }

    h2, p {
        color: $white;
        text-align: center;
        margin: 0 auto;
    }

    h2 {
        @include media-breakpoint-down(sm) {
            font-size: 34px;
            line-height: 40px;
        }
    }

    p {
        margin-top: 24px;
        max-width: 640px;

        @include media-breakpoint-down(sm) {
            margin-top: 12px;
        }
    }

    &-cards {
        margin-top: 64px;
        display: flex;
        justify-content: space-between;
        align-items: stretch;

        @include media-breakpoint-down(md) {
            flex-wrap: wrap;
        }

        @include media-breakpoint-down(sm) {
            margin-top: 48px;   
        }

        &-item {
            border-radius: 20px;
            padding: 40px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            box-sizing: border-box;

            &.ua {
                padding: 40px 28px;
            }

            @include media-breakpoint-down(md) {
                width: 100%;
                margin-bottom: 16px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            @include media-breakpoint-down(sm) {
                padding: 32px;
            }

            &-icon {
                max-height: 118px;
                height: 100%;
                
                img {
                    height: 100%;
                    width: auto;
                }
            }
            
            &-text {
                margin-top: 160px;
                position: relative;

                @include media-breakpoint-down(lg) {
                    margin-top: 52px;
                }

                @include media-breakpoint-down(sm) {
                    margin-top: 46px;
                }

                h4 {
                    font-weight: 500;
                    font-size: 36px;
                    line-height: 43px;
                    max-width: 220px;
                    word-break: break-word;

                    @include media-breakpoint-down(sm) {
                        font-size: 30px;
                        line-height: 36px;
                    }
                }

                p {
                    font-size: 14px;
                    line-height: 22px;
                    font-weight: 400;
                    margin-top: 32px;
                    text-align: left;

                    @include media-breakpoint-down(md) {
                        margin-top: 16px;
                    }

                    @include media-breakpoint-down(sm) {
                        font-size: 13px;
                        line-height: 20px;
                    }
                }
            }

            &:nth-child(2) {
                margin: 0 48px;

                @include media-breakpoint-down(lg) {
                    margin: 0px 18px;
                }

                @include media-breakpoint-down(md) {
                    margin: 0px;
                    margin-bottom: 16px;
                }
            }
        }
    }
}