.app-footer {
    padding: 80px 0px;
    background-color: $white;

    @include media-breakpoint-down(sm) {
        padding: 48px 0px;
    }

    @include media-breakpoint-down(xs) {
        padding: 0px 0px 32px;
    }

    .row {
        align-items: flex-end;
    }

    span, a {
        font-size: 16px;
        line-height: 22px;
        color: $main-black;
        font-weight: 400;
    }

    span a {
        text-decoration: underline;
    }

    .col-lg-3 {
        @include media-breakpoint-down(xs) {
            order: 1;
            margin-top: 48px;
        }
    }

    ul {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        @include media-breakpoint-up(xs) {
            flex-flow: wrap-reverse;
        }

        @include media-breakpoint-down(md) {
            margin-top: 24px;
        }

        li {
            width: 28%;
            margin-top: 12px;

            @include media-breakpoint-down(sm) {
                width: 50%; 
                margin-top: 16px;           
            }

            @include media-breakpoint-down(xs) {
                width: 100%;
            }
        }
    }

    &-link {
        @include media-breakpoint-down(xs) {
            &.insta {
                order: -2;
            }
            &.facebook {
                order: -1;
                margin-bottom: 32px;
            }

            &.designed {
                order: 2;
            }

            &.developed {
                order: 1;
            }

            &.photo {
                order: 1;
                margin-top: 32px;
            }

            &.cookies {
                display: none;
            }
        }
    }
}