.app-principles {
    position: relative;
    padding-top: 140px;
    background-color: $white;

    @include media-breakpoint-down(sm) {
        padding-top: 72px;
    }

    h3 {
        font-size: 48px;
        line-height: 52px;
        text-align: center;

        @include media-breakpoint-down(sm) {
            font-size: 24px;
            line-height: 28px;
        }
    }

    &-wrap {
        display: flex;
        justify-content: center;
        margin-top: 96px;

        @include media-breakpoint-down(sm) {
            flex-wrap: wrap;
            margin-top: 8px;
        }
    }

    &-item {
        display: flex;
        flex-direction: column;
        align-items: center; 
        max-width: 200px;
        margin: 0px 18px;

        @include media-breakpoint-down(sm) {
            flex-direction: row;
            margin: 0;
            width: 100%;
            max-width: 100%;
            padding: 28px 0px;
            border-bottom: 1px solid rgba(12, 12, 12, .2);

            &:last-child {
                border-bottom: none;
            }
        }

        img {
            width: 64px;
            height: 64px;
            margin-bottom: 16px;

            @include media-breakpoint-down(sm) {
                margin-bottom: 0;
                width: 48px;
                height: 48px;
            }
        }

        p {
            text-align: center;
            font-size: 14px;
            line-height: 19px;

            @include media-breakpoint-down(sm) {
                margin-left: 32px;
                text-align: left;
            }
        }
    }
}