.app-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $main-black;
  padding: 9px 20px;
  transition: .3s;
  z-index: 3;

  .navbar {
      justify-content: space-between;
      padding: 0;

      &-brand {
          &.mobile {
              display: none;

              @include media-breakpoint-down(lg) {
                  display: flex;
              }
          }
          
          &.desktop {
              display: flex;
              
              @include media-breakpoint-down(lg) {
                  display: none;
              }
          }

          &-logo {
            transition: .3s;

            &-dark {
              display: none;
            }
          }

          img {
            @include media-breakpoint-down(sm) {
              max-width: 100px;
            }
          }
      }

      &-collapse {
          width: 100%;
          justify-content: space-between;
      }

      &-toggler {
          display: none;
          color: transparent;
          box-shadow: none;
          
          &:active, &:focus {
            box-shadow: none;
          }
    
          @include media-breakpoint-down(lg) {
            padding: 0;
            display: flex;
            justify-content: space-between;
            width: 30px;
            height: 26px;
            padding: 5px;
            flex-direction: column;
            position: relative;
            
            &-icon {
              width: 100%;
              height: 1px;
              margin-bottom: 4.5px;
              transition: .3s all;
              position: relative;
              display: block;
              background-color: $white;
              background-image: none;
              
              &:last-child {
                margin-bottom: 0;
              }

              &.first {
                transform: translateY(-50%) rotate(45deg);
                top: 50%;
              }
              &.second {
                display: none;
              }
              &.third {
                transform: translateY(50%) rotate(-45deg);
                top: -50%;
              }
            }
            
            &.collapsed {   
              .navbar-toggler-icon {
                top: 0;
                transform: rotate(0deg);
                display: flex;
              }
            }
          }
      }

      &-nav {
        @include media-breakpoint-up(lg) {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          align-items: center;
        }
        
        @include media-breakpoint-down(xl) {
          position: relative;
          left: 0;
          transform: translateX(0px);
        }

        @include media-breakpoint-down(lg) {
          margin-top: 24px;
        }

        .nav-link {
          color: $white;
          font-size: 16px;
          font-weight: 400;
          padding-left: 12px;
          padding-right: 12px;
          transition: .3s;

          @include media-breakpoint-down(lg) {
            padding-top: 18px;
            padding-bottom: 18px;
          }

          @include media-breakpoint-down(sm) {
            padding-top: 8px;
            padding-bottom: 8px;
            font-size: 24px;
            line-height: 33px;
          }

          &:hover, &:focus, &.active {
              color: $white;
              opacity: 0.7;
          }
        }

        .nav-item {
          &.btn {
            width: 100%;
            padding: 0;
            margin-bottom: 23px;

            a {
              width: 100%;
              padding: 0;
            }

            @include media-breakpoint-up(lg) {
              display: none;
            }
          }
        }
      }

      &-right {
        display: flex;
        align-items: center;
        margin-left: auto;

        @include media-breakpoint-up(lg) {
          order: 1;
          margin-left: 0;
        }
        
        .nav-link {
          &.icon {
            padding: 0.5rem 9px;
            transition: .3s;
          }

          &.btn {
            padding-left: 0;
            padding-right: 0;

            &:hover {
              opacity: 1;
            }

            @include media-breakpoint-down(lg) {
              display: none;
            }
          }
        }
      }
  }

  &.light {
    background-color: $white;
    box-shadow: 0px 1px 16px -13px $main-black;

    .navbar {
      &-brand {
        &-logo {
          &-light {
            display: none;
          }

          &-dark {
            display: flex;
          }
        }
      }

      
      .nav-link {
        color: $main-black;
      }
      
      &-right {
        .icon {
          filter: invert(1);
          -webkit-filter: invert(1);
        }
      }

      &-toggler {
        border: none;

        &-icon {
          background-color: $main-black;
        }
      } 
    }
  }

  .app-button {
    @include media-breakpoint-down(sm) {
      width: 100%;
      margin-top: 24px;
    }
  }
}