.app-disaster {
    position: relative;
    background-color: $white;
    padding-top: 144px;
    width: 100%;

    @include media-breakpoint-down(sm) {
        padding-top: 72px;
    }

    .container, .col-lg-5 {
        @include media-breakpoint-down(sm) {
            padding: 0;
        }
    }

    &-content {
        @include media-breakpoint-down(md) {
            padding: 0px 72px;
        }

        @include media-breakpoint-down(sm) {
            padding: 0px 20px;
        }

        h3 {
            font-size: 44px;
            line-height: 57px;
            color: $main-black;
            margin-top: 16px;

            @include media-breakpoint-down(lg) {
                font-size: 28px;
                line-height: 33px;
                margin-top: 20px;
            }
        }

        &-needed {
            display: flex;
            align-items: center;
            margin-top: 48px;

            @include media-breakpoint-down(sm) {
                margin-top: 32px;
                flex-wrap: nowrap;
            }

            img {
                width: 64px;
                height: 64px;
                pointer-events: none;
                user-select: none;

                @include media-breakpoint-down(sm) {
                    width: 15%;
                    height: auto;
                }
            }

            p {
                margin-left: 16px;
                
                @include media-breakpoint-down(md) {
                    margin-left: 24px;
                }

                @include media-breakpoint-down(sm) {
                    margin-left: auto;
                }
            }
        }
    }

    &-img {
        position: relative;

        &-wrap {
            position: relative;
            max-height: 686px;
            height: 100%;
            overflow: hidden;

        }
        
        &-item {
            position: relative;
            height: 100%;
            max-height: 686px;
            overflow: hidden;
            // border-radius: 20px;
            display: flex !important;
            align-items: center;

            // @include media-breakpoint-down(sm) {
            //     border-radius: 0px;
            // }

            &::after {
                content: "";
                background: linear-gradient(180deg, rgba(16, 16, 16, 0) 0%, rgba(16, 16, 16, 0.9) 87.5%);
                width: 100%;
                user-select: none;
                -webkit-user-select: none;
                -moz-user-select: none;
                pointer-events: none;
                height: 25%;
                position: absolute;
                left: 0;
                bottom: 0;
                z-index: 1;
            }
            
        }

        &-bottom {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            padding: 16px 24px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            z-index: 2;

            &-text {
                font-size: 12px;
                line-height: 16px;
                font-weight: 400;
                color: $white;
                max-width: 75%;
                margin-right: auto;
                text-align: left;
            }

            &-current {
                font-size: 12px;
                line-height: 16px;
                font-weight: 400;
                color: $white;
                margin: 0px 18px;
                white-space: nowrap;

                @include media-breakpoint-down(sm){
                    margin: 0;
                }
            }
        }

        .arrow {
            height: 18px;
            width: auto;
            cursor: pointer;
            transform-origin: center;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                position: relative;
                height: 100%;
                width: auto;
            }

            &.prev {
                transform: rotate(180deg);
            }

            &.disabled {
                opacity: 0.2;

                @include media-breakpoint-down(sm) {
                    opacity: .5;
                }
            }

            &.mobile {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                z-index: 1;

                &.prev {
                    left: 20px;
                    transform: translateY(-50%) rotate(180deg);
                }

                &.next {
                    right: 20px;
                }
            }
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        
        @include media-breakpoint-down(md) {
            margin-top: 32px;
            width: 100%;
        }

        @include media-breakpoint-down(sm) {
            max-height: initial;
        }
    }

    &-donate {
        margin-top: 48px;
        padding: 48px 64px;
        border-radius: 20px;
        background-color: $main-yellow;
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        @include media-breakpoint-down(sm) {
            border-radius: 0px;
            margin-bottom: -0.5px;
            margin-top: 0;
            padding: 48px 20px;
            flex-direction: column;
            align-items: flex-start;
        }
        
        .nav-link {
            padding: 0;

            &:hover {
                opacity: 1;
            }
            
            @include media-breakpoint-down(sm) {
                width: 100%;
            }
        }

        p {
            line-height: 39px;
            max-width: 65%;

            @include media-breakpoint-down(sm) {
                line-height: 24px;
                margin-bottom: 32px;
                max-width: 100%;
            }

            span {
                font-weight: 600;
            }
        }

        .app-button {
            @include media-breakpoint-down(sm) {
                width: 100%;
            }
        }
    }

    .slick {
        &-track {
            display: flex !important;
            align-items: stretch;
        }

        &-list {
            border-radius: 20px;
            overflow: hidden;

            @include media-breakpoint-down(sm) {
                border-radius: 0px;
            }
        }
    }
}