$sizes: (
    'xl': '1440',
    'lg': '1200',
    'md': '992',
    'sm': '768',
    'xs': '576'
);

@mixin media-breakpoint-down($breakpoint) {
    $prop: map-get($sizes, $breakpoint);
        
    @media screen and (max-width: #{$prop}px) {
        @content;
    }
}

@mixin media-breakpoint-up($breakpoint) {
    $prop: map-get($sizes, $breakpoint);
        
    @media screen and (min-width: #{$prop}px) {
        @content;
    }
}