.app-slider {
    position: relative;
    background-color: $main-black;
    margin-bottom: 240px;
    
    @include media-breakpoint-down(sm) {
        margin-bottom: 56px;
    }

    &-wrap {
        display: flex;
        // transition: transform 0.5s cubic-bezier(.25,.72,.51,.96);

        @include media-breakpoint-down(sm) {
            position: relative;
            flex-direction: column;

            &.blur {
                &::after {
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 75vw;
                    z-index: 1;
                    user-select: none;
                    pointer-events: none;
                    background: linear-gradient(180deg, rgba(16, 16, 16, 0) 0%, #0C0C0C 61.98%);
                }
            }
        }
    //     max-height: 720px;
    //     position: relative;
    //     display: flex;
    //     align-items: stretch;
    //     margin-right: calc(-50vw + 50%);
    //     height: 100%;
    //     will-change: transform;
    }

    &-item {
        // position: relative;
        user-select: none;
        -webkit-user-select: none;
        cursor: pointer;
        // width: 100%;
        height: 100%;
        flex-shrink: 0;
        position: relative;
        width: 550px;
        // width: 23vw;
        // margin-right: 4vw;
        // padding: 0px 12px;
        margin: 0px 12px;
        overflow: hidden;
        z-index: 1;
        border-radius: 20px;
             
        @include media-breakpoint-down(lg) {
            width: 394px;
        }

        @include media-breakpoint-down(sm) {
            position: relative;
            border-radius: 0px;
            width: 100vw;
            margin: 0;
            max-height: 90vh;
            margin-bottom: 8px;

            &.hidden {
                display: none;
            }

            &.hovered {
                img {
                    
                    &.current {
                        -webkit-filter: grayscale(0%);
                        filter: grayscale(0%);
                        opacity: 1;
                    }
                }
        
                // &::after {
                //     opacity: 0;
                // }
        
                // .app-slider-item-text {
                //     opacity: 0;
                // }
            }
        }
        
        @include media-breakpoint-up(sm) {
            &:hover {
                img {
                    
                    &.current {
                        -webkit-filter: grayscale(0%);
                        filter: grayscale(0%);
                        opacity: 1;
                    }
                }
    
                &::after {
                    opacity: 0;
                }
    
                .app-slider-item-text {
                    opacity: 0;
                }
            }
        }

        &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            transition: .5s;
            height: 50%;
            z-index: 1;
            background: linear-gradient(180deg, rgba(16, 16, 16, 0) 0%, rgba(16, 16, 16, 0.8) 100%);
        }

        &-img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            user-select: none;
            pointer-events: none;
            -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
            filter: grayscale(100%);
            overflow: hidden;
            opacity: 0;
            

            &.current {
                opacity: 1;
                position: relative;
            }
    

            &-wrap {
                width: 100%;
                height: 100%;
                overflow: hidden;
                position: relative;

                @include media-breakpoint-down(sm) {
                    border-radius: 0px;
                }
            }
        }

        &-text {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 2;
            transform: translate(-50%, -50%);
            
            @include media-breakpoint-down(sm) {
                top: auto;
                bottom: 62px;
                transform: translate(0px, 0px);
                left: 20px;

                &.hide {
                    display: none;
                }
            }
            
            h4 {
                color: $white;
                font-weight: 500;
                font-size: 48px;
                line-height: 67px;

                @include media-breakpoint-down(sm) {
                    font-size: 28px;
                    line-height: 33px;
                }
            }

            h5 {
                margin-top: 8px;
                color: $white;
                font-size: 16px;
                font-weight: 500;
                line-height: 22px;
                text-align: center;

                @include media-breakpoint-down(sm) {
                    text-align: left;
                    margin-top: 0;
                }
            }
        }

        .photo-by {
            // transition: 1s;
            opacity: 0;

            @include media-breakpoint-down(sm) {
                bottom: 28px;
                left: 20px;
                z-index: 2;
            }

            &.current {
                opacity: 1;
            }
        }
    }

    &-arrows {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        display: flex;
        pointer-events: none;

        @include media-breakpoint-down(sm) {
            display: none;
        }

        .container {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &-item {
            cursor: pointer;
            user-select: none;
            padding: 10px;
            pointer-events: all;

            &.left {
                transform-origin: center;
                transform: rotate(180deg);
            }
        }
    }

    &-more {
        text-align: center;
        padding: 20px;
        font-size: 16px;
        line-height: 22px;
        color: $white;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .flickity {
        $height: 720px;

        &-viewport {
            height: $height;
        }

        &-slider {
            position: relative;
            display: flex;
            align-items: stretch;
            justify-content: flex-start;
            // margin-right: calc(-50vw + 50%);
            // height: 100%;
            width: 100%;
            // height: 100%;
            height: $height;
        }

        &-cell {
            position: absolute;
            left: 0;
        }
    }

    .slick {
        &-track {
            display: flex;
            align-items: stretch;
        }

        &-slide {
            margin: 0 12px;
            max-width: 550px;
            user-select: none;
            -webkit-user-select: none;
            border-radius: 20px;
            overflow: hidden;
            
            @include media-breakpoint-down(sm) {
                border-radius: 12px;
                margin: 0 8px;
                max-width: 70vw;
            }

            &:last-child {
                margin-right: 0;
            }

            &:first-child {
                margin-left: 0;
            }
        }
    }
}