.app-organisation {
    position: relative;
    padding-top: 48px;
    background-color: $white;

    @include media-breakpoint-down(md) {
        margin-bottom: -0.5px;
    }

    @include media-breakpoint-down(sm) {
        padding-top: 72px;
        margin-bottom: -1px;
    }
    
    &-wrap {
        background-color: $gray-200;
        border-radius: 20px;
        width: 100%;
        padding: 140px 155px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include media-breakpoint-down(md) {
            padding: 48px 32px;
        }

        h2, p {
            text-align: center;
        }

        h2 {
            margin-top: 16px;
            margin-bottom: 24px;

            @include media-breakpoint-down(sm) {
                font-size: 24px;
                line-height: 28px;
            }
        }

        p {

            @include media-breakpoint-down(sm) {
                font-size: 14px;
                line-height: 22px;
            }

            a {
                color: $main-black;
                text-decoration: underline;
            }
        }

        img {

            @include media-breakpoint-down(sm) {
                width: 70%;
                height: auto;
                margin-top: 48px;
            }

            margin-top: 140px;
        }
    }
}