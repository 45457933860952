.app-news {
    width: 100%;
    position: relative;
    margin-top: 48px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include media-breakpoint-down(sm) {
        margin-top: 16px;
    }

    &-item {
        width: calc(33% - 13px);
        height: 180px;
        padding: 24px 40px;
        border-radius: 20px;
        background-color: $white;
        margin-bottom: 24px;
        // transition: .3s;
        cursor: pointer;

        &:last-child {
            margin-bottom: 0;
        }

        &:hover {
            background-color: transparent;
            border: 2px solid #000;
            padding: 22px 38px;
        }
        
        @include media-breakpoint-down(md) {
            width: calc(50% - 13px);
        }
        
        @include media-breakpoint-down(sm) {
            width: 100%;
            padding: 24px;

            &:hover {
                background-color: transparent;
                border: 2px solid #000;
                padding: 22px;
            }
        }

        
        a {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;

            &:hover {
                opacity: 1 !important;
            }
        }

        h4 {
            font-size: 24px;
            line-height: 33px;
            margin-bottom: 4px;
        }
    
        p {
            font-size: 14px;
            line-height: 19px;
            font-weight: 400;    
            color: $black;
        }

        img {
            align-self: flex-end;
        }
    }
}