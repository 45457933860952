.app-tag {
    width: auto;
    border: 1px solid rgba(255, 255, 255, 0.15);
    border-radius: 20px;
    display:inline-block;
    // display: flex;
    // align-items: center;
    // justify-content: flex-start;
    padding: 2px 20px;

    span {
        width: auto;
        color: $white;
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        white-space: nowrap;
    }

    &.black {
        border: 1px solid rgba(12, 12, 12, 0.15);

        span {
            color: $main-black;
        }
    }
}