.app-photo {
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
    
    @include media-breakpoint-down(sm) {
        min-height: auto;
        height: 375px;
    }

    &.parrallax {
        min-height: 100vh;
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        @include media-breakpoint-down(md) {
            background-attachment: initial;
        }

        // @include media-breakpoint-down(sm) {
            // background-position: -650px;
        // }
    }

    img {
        width: 100vw;
        // width: 100%;
        height: auto;

        @include media-breakpoint-down(sm) {
            width: 100%;
            height: 100%;
            position: absolute;
            object-fit: cover;
            left: 0;
            top: 0;
        }
    }

    .content {
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 0px 25px;
        text-align: center;
    }

    h2 {
        color: $white;
        font-size: 64px;
        text-align: center;
        font-weight: 500;

        @include media-breakpoint-down(sm) {
            font-size: 34px;
            line-height: 40px;
        }
    }

    p {
        font-size: 24px;
        line-height: 33px;
        color: $white;

        @include media-breakpoint-down(sm) {
            font-size: 16px;
            line-height: 24px;
            margin-top: 8px;
        }
    }
}