.app-war-text {
    position: relative;
    background-color: $main-black;
    padding: 246px 0px;

    @include media-breakpoint-down(sm) {
        padding: 140px 0px;
    }
    
    h3 {
        margin-top: 16px;
        position: relative;
        font-size: 44px;
        line-height: 57px;
        font-weight: 500;
        color: $white;

        @include media-breakpoint-down(sm) {
            font-size: 28px;
            line-height: 33px;
        }

        span {
            color: $red;
        }
    }
}