.app-cookie {
    padding: 9px 20px;
    background-color: $main-yellow;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 3;

    @include media-breakpoint-down(sm) {
        padding: 16px 20px 40px;
        align-items: flex-start;
    }

    &-message {
        display: flex;
        align-items: center;

        @include media-breakpoint-down(sm) {
            justify-content: flex-start;
            flex-wrap: wrap;
            padding-left: 30px;
        }

        img {
            @include media-breakpoint-down(sm) {
                position: absolute;
                left: 20px;
                top: 16px;
            }
        }

        p {
            font-size: 14px;
            line-height: 14px;
            font-weight: 400;
            margin: 0px 12px;
            
            @include media-breakpoint-down(sm) {
                line-height: 19px;
                margin: 0px;
                max-width: 90%;
                margin-bottom: 8px;
            }
        }

        a {
            font-size: 14px;
            font-weight: 400;
            color: $main-black;
            text-decoration: underline;
        }
    }

    &-close {
        width: 25px;
        height: 25px;
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: space-between;  
        cursor: pointer;                
        
        // @include media-breakpoint-down(sm) {
        //     position: absolute;
        //     top: 50%;
        //     transform: translateY(-50%);
        //     right: 12px;
        // }

        &::after, &::before {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            width: 17px;
            height: 3px;
            transform-origin: center;
            background-color: $main-black;
        }

        &::after {
            transform: translate(-50%, -50%) rotate(-45deg);
        }

        &::before {
            transform: translate(-50%, -50%) rotate(45deg);
        }
    }
}