$white: #fff;

$main-black: #0C0C0C;
$black-lighter: #313131;
$main-yellow: #FFE600;

$opacity: rgba(0, 0, 0, 0.1);

$main-yellow-darken: #E0C532;

$gray-100: #666;
$gray-200: #F6F6F6;
$gray-300: #F4F4F4;
$gray-400: #DADADA;

$red: #B60E03;
$blue: #0034EE;