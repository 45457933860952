.app-language-switcher {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0px 22px;
    padding: 0px 10px;
    margin-left: 23px;

    @include media-breakpoint-down(sm) {
        margin: 0px 12px;
    }
    
    span {
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
        color: $white;    
        text-transform: capitalize;
    }
    
    &-current {
        display: flex;
        align-items: center;
        cursor: pointer;

        &.active {
            i {
                transform: rotate(0deg);
            }
        }
        
        i {
            // color: $white;    
            // font-size: 12px;
            width: 0;
            height: 0;
            border: 3px solid transparent;
            border-top: 0;
            transform: rotate(180deg);
            border-bottom: 6px solid $white;

            margin-left: 6px;
            transition: .3s;
        }
    }

    &-menu {
        position: absolute;
        top: calc(100% + 10px);
        left: 0;
        display: flex;
        flex-direction: column;
        transition: .5s;
        opacity: 0;
        z-index: -1;
        width: 100%;
        padding: 0px 10px;
        background-color: $black-lighter;
        border-radius: 6px;

        li {
            cursor: pointer;
            padding: 0.2rem 0px;
        }

        &.active {
            opacity: 1;
            z-index: 1;
        }
    }

    &.light {
        span {
            color: $main-black;
        }

        i {
            border-bottom: 6px solid $main-black;
        }

        .app-language-switcher-menu {
            background-color: $gray-200;
            box-shadow: 0px 0px 5px -3px $main-black;
            
            span {
                color: $main-black;
            }
        }
    }
}