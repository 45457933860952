.app-form {
    margin-top: 48px;
    margin-bottom: 96px;

    @include media-breakpoint-down(sm) {
        margin-bottom: 48px;
        margin-top: 16px;
    }
    
    .row {
        align-items: stretch;
    }

    .col-xl-4 {
        @include media-breakpoint-down(lg) {
            position: relative;
            order: 2;
        }
    }

    &-sidebar {
        height: 100%;
        border-radius: 20px;
        background-color: $main-yellow;
        padding: 48px 40px;
        display: flex;
        flex-direction: column;

        @include media-breakpoint-down(sm) {
            padding: 24px;
        }

        &-indicators {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;

            &-item {
                width: 8px;
                height: 8px;
                background-color: $main-black;
                border-radius: 8px;
                margin: 0px 4px;

                &.active {
                    width: 100px;
                }
            }
        }

        &-card {

            &-icon {
                width: 80px;
                height: 80px;
                border-radius: 40px;
                border: 2px solid $main-black;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 16px;

                img {
                    width: 33px;
                }
            }

            h4 {
                font-size: 32px;
                line-height: 35px;
                max-width: 50%;
                font-weight: 500;
                
                @include media-breakpoint-down(sm) {
                    font-size: 24px;
                    line-height: 28px;
                    margin-bottom: 8px;
                    max-width: 100%;
                }
            }
        }
        
        button {
            margin-top: 24px;
            width: 100%;
            background-color: $opacity;
            
            @include media-breakpoint-down(sm) {
                margin-top: 32px;
            }
        }

        p {
            margin-top: auto;
            font-size: 16px;
            line-height: 22px;
            font-weight: 400;
        }
    }

    &-main {
        background-color: $white;
        width: 100%;
        padding: 48px 64px;
        border-radius: 20px;

        @include media-breakpoint-down(lg) {
            margin-top: 0px;
            margin-bottom: 16px;
        }

        @include media-breakpoint-down(sm) {
            padding: 24px;
        }

        &-clipboards {
            display: flex;
            flex-direction: column;

            &-item {
                display: flex;
                flex-direction: column;
                margin-bottom: 24px;

                &:focus {
                    img {
                        opacity: .5;
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }

                span {
                    font-size: 16px;
                    line-height: 22px;
                    font-weight: 400;
                    margin-bottom: 4px;

                    @include media-breakpoint-down(sm) {
                        font-size: 14px;
                        line-height: 20px;
                    }
                }

                &-window {
                    display: flex;
                    border-radius: 20px;
                    border: 1px solid $gray-400;
                    padding: 20px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    position: relative;
                    cursor: pointer;
                    overflow: hidden;

                    @include media-breakpoint-down(sm) {
                        justify-content: flex-end;
                        padding: 16px;
                        border-radius: 16px;
                        
                        &::after {
                            content: "";
                            width: 80px;
                            height: 100%;
                            position: absolute;
                            right: 0;
                            top: 0;
                            background: linear-gradient(270deg, $white 70%, rgba(255,255,255,0) 100%);
                        }
                    }

                    p {
                        font-size: 24px;
                        line-height: 33px;
                        font-weight: 500;
                        white-space: nowrap;

                        @include media-breakpoint-down(sm) {
                            position: absolute;
                            left: 16px;
                            top: 50%;
                            font-size: 16px;
                            line-height: 22px;
                            transform: translateY(-50%);
                        }
                    }

                    img {
                        width: 32px;
                        height: auto;
                        transition: .3s;
                        z-index: 1;

                        @include media-breakpoint-down(sm) {
                            width: 24px;
                        }
                    }
                }
            }
        }

        &-cta {
            margin-top: 96px;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            @include media-breakpoint-down(sm) {
                margin-top: 32px;
                flex-direction: column;
            }

            p {
                font-size: 16px;
                line-height: 22px;
                font-weight: 400;
                text-transform: uppercase;
                max-width: 62%;

                @include media-breakpoint-down(sm) {
                    max-width: 100%;
                    margin-bottom: 24px;
                    font-size: 13px;
                    line-height: 18px;
                }
            }

            .app-button {
                @include media-breakpoint-down(xs) {
                    width: 100%;
                }
            }
        }
    }

    &-popup {
        position: fixed;
        width: calc(100vw + 600px);
        height: 100vh;
        left: 0;
        top: 0;
        display: flex;
        justify-content: flex-end;
        align-items: stretch;
        transition: .6s;
        -webkit-transition: .6s;
        -moz-transition: .6s;
        z-index: 4;
        visibility: hidden;

        @include media-breakpoint-down(sm) {
            width: 100vw;
            left: 100%;
            bottom: 0;
            top: 0;
            min-height: 100vh;
        }
        
        &.visible {
            visibility: visible;
            opacity: 1;
            width: 100vw;
            left: 0%;
        }

        &-bcg {
            width: 100%;
            height: 100%;
            background: $main-black;
            opacity: .4;   
            
            @include media-breakpoint-down(sm) {
                width: auto;
            }
        }

        &-wrap {
            height: 100%;
            background-color: $white;
            max-width: 576px;
            width: 100%;
            display: flex;
            flex-direction: column;
            padding: 48px;
            overflow-y: auto;

            @include media-breakpoint-down(sm) {
                max-width: 100%;
                padding: 0px 20px;
                // padding-top: 0;
                min-height: 100vh;
                position: relative;
                z-index: 4;
                overflow-y: scroll;
                -webkit-overflow-scrolling: touch
            }

            &-head {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 48px;

                @include media-breakpoint-down(sm) {
                    margin-top: 50px;
                }

                h4 {
                    font-size: 28px;
                    font-weight: 500;
                    color: $main-black;
                }

                &-close {
                    width: 25px;
                    height: 25px;
                    display: flex;
                    position: relative;
                    flex-direction: column;
                    justify-content: space-between;  
                    cursor: pointer;                  

                    &::after, &::before {
                        content: "";
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        width: 17px;
                        height: 3px;
                        transform-origin: center;
                        background-color: $main-black;

                        @include media-breakpoint-down(sm) {
                            width: 24px;
                        }
                    }

                    &::after {
                        transform: translate(-50%, -50%) rotate(-45deg);
                    }

                    &::before {
                        transform: translate(-50%, -50%) rotate(45deg);
                    }
                }
            }

            &-body {
                display: flex;
                flex-direction: column;

                @include media-breakpoint-down(sm) {
                    position: relative;
                    margin-bottom: 110px;
                }

                span {
                    font-size: 16px;
                    line-height: 22px;
                    text-transform: uppercase;
                    font-weight: 400;

                    @include media-breakpoint-down(sm) {
                        font-size: 14px;
                        line-height: 20px;
                    }
                }
            }

            &-trigger {
                display: flex;
                justify-content: space-between;
                align-items: stretch;
                width: 100%;
                background-color: $gray-200;
                border-radius: 8px;
                padding: 8px;
                margin-bottom: 16px;

                &-item {
                    height: 100%;
                    width: calc(50% - 2px);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 10px;
                    border-radius: 8px;
                    cursor: pointer;

                    &.current {
                        background-color: $main-yellow;

                        span {
                            font-weight: 500;
                        }
                    }

                    span {
                        font-size: 16px;
                        line-height: 22px;
                        font-weight: 400;
                        text-align: center;
                        color: $main-black;
                        text-transform: uppercase;

                        @include media-breakpoint-down(sm) {
                            font-size: 13px;
                            line-height: 18px;
                        }
                    }
                }
            }

            &-input {
                display: flex;
                flex-direction: column;
                margin-bottom: 16px;

                input {
                    height: 58px;
                    margin-top: 8px;
                    width: 100%;
                    border: 1px solid $gray-400;
                    border-radius: 16px;
                    padding: 0px 16px;
                    position: relative;
                    transition: .3s;

                    // &:focus {
                    //     border: 2px solid $main-black;
                    // }

                    // &::-webkit-datetime-edit {
                    //     cursor: pointer;

                    //     i {
                    //         cursor: pointer;
                    //     }
                    // }
                }

                &.required, &.invalid {
                    &::after {
                        font-size: 14px;
                        line-height: 19px;
                        font-weight: 400;
                        color: $red;
                        position: relative;
                        margin-top: 4px;
                    }
    
                    input {
                        background: transparent;
                        border: 2px solid $red;
                    }
                }

                &.required{
                    &::after {
                        content: "Required";
                    }
                }

                &.invalid{
                    &::after {
                        content: "Invalid email";
                    }
                }

                // &.email {    
                //     &.invalid {
                //         &::after {
                //             content: "Invalid email";
                //             font-size: 14px;
                //             line-height: 19px;
                //             font-weight: 400;
                //             color: $red;
                //             position: relative;
                //             margin-top: 4px;
                //         }

                //         input {
                //             background: transparent;
                //             border: 2px solid $red;
                //         }
                //     }    

                // }

                &-date {
                    height: 58px;
                    margin-top: 8px;
                    width: 100%;
                    border: 1px solid $gray-400;
                    border-radius: 16px;
                    padding: 0px 16px;
                    cursor: pointer;

                    .react-date-picker__wrapper {
                        border: none;
                    }

                    .react-date-picker__clear-button {
                        display: none;
                    }

                    .react-calendar__navigation__arrow {
                        font-size: 22px;
                        color: $main-black;
                    }

                    .react-calendar__tile--now {
                        border-radius: 4px;
                        background: $main-yellow;
                        
                        &:hover, &:enabled, &:focus {
                            background: $main-yellow !important;
                        }
                    }

                    .react-calendar__tile--active {
                        border-radius: 4px;
                        background: none;
                        color: $main-black;
                        border: 2px solid $main-black;
                        
                        &:hover, &:enabled, &:focus {
                            background: none !important;
                        }
                    }

                    .react-date-picker__calendar {
                        @include media-breakpoint-down(sm) {
                            width: 100%;
                        }
                    }

                    abbr {
                        text-decoration: none !important;
                    }

                    .react-calendar {
                        border-radius: 8px;
                        margin-bottom: 8px;
                        overflow: hidden;
                        border: 1px solid $gray-400;
                        box-shadow: 1px 1px 8px -5px $gray-400;
                    }

                    .react-date-picker__inputGroup {
                        display: flex;
                        align-items: center;
                    }

                    input {
                        padding: 0;
                        border: none;
                        margin-top: 0px;
                        height: auto;
                        border-radius: 0px;

                        &:focus {
                            border: none;
                        }
                    }
                }
            }

            &-send {
                margin-top: auto;
                display: flex;
                align-items: center;
                justify-content: space-between;

                @include media-breakpoint-down(sm) {
                    flex-direction: column;
                    margin-top: 24px;
                    align-items: flex-start;
                }

                .app-button {
                    @include media-breakpoint-down(sm) {
                        width: 100%;
                        margin-top: 24px;
                    }
                }
            }

            &-success {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: auto;

                &-icon {
                    width: 100%;
                    max-width: 64px;
                    
                    img {
                        width: 100%;
                        height: auto;
                    }
                }

                h4 {
                    margin-top: 24px;
                    font-size: 32px;
                }

                p {
                    text-align: center;
                    font-size: 16px;
                }
            }
        }
    }
}