.app-activities {
    position: relative;
    padding: 140px 0px;
    background-color: $white;

    @include media-breakpoint-down(sm) {
        padding: 72px 0px;
    }

    &-cards {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        &-item {
            width: calc(50% - 12px);
            height: 340px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            background-color: $gray-200;
            border-radius: 20px;
            margin-top: 24px;
            padding: 24px 40px;

            @include media-breakpoint-down(sm) {
                width: 100%;
                padding: 24px 32px;
                height: auto;
                margin-top: 16px;
            }

            &.ua {
                h4 {
                    font-weight: 600;
                }
            }

            &-text {
                h4 {
                    font-size: 24px;
                    line-height: 33px;
                    font-weight: 500;
                    color: $main-black;
                    margin-bottom: 4px;
                }

                p {
                    font-size: 16px;
                    line-height: 22px;
                    font-weight: 400;
                    
                    &::before {
                        content: "•";
                        padding: 0px 5px;
                    }
                    
                    @include media-breakpoint-down(sm) {
                        font-size: 13px;
                        line-height: 18px;

                    }
                }
            }

            &-icons {
                display: flex;

                @include media-breakpoint-down(sm) {
                    margin-top: 32px;
                }

                img {
                    width: 64px;
                    height: 64px;
                    user-select: none;
                    pointer-events: none;
                    
                    @include media-breakpoint-down(sm) {
                        height: 48px;
                        width: 48px;
                    }
                }
            }
        }
    }
}