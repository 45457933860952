.app-button {
    position: relative;
    white-space: nowrap;
    padding: 18px 42px;
    border-radius: 16px;
    background-color: $main-yellow;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $main-black;
    font-size: 16px;
    transition: 200ms;
    font-weight: 500;
    
    &:hover {
        color: $main-black;
    }

    &:hover:not(:disabled, &.disabled) {
        background-color: $blue;
        color: $white;

        img {
            filter: invert(1) grayscale(100%);
            -webkit-filter: invert(1) grayscale(100%);
            -moz-filter: invert(1) grayscale(100%);
        }
    }

    &:disabled {
        cursor: not-allowed;
        opacity: .7;
    }

    &.white {
        background-color: $white;
    }

    img {
        max-height: 18px;
        margin-right: 10px;
        transition: 200ms;
    }
} 
