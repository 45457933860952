@import "~bootstrap/scss/bootstrap";

@import "./styles/__colors";
@import './styles/__helpers';
@import './styles/_spacing';
@import './styles/__app';

html, #root {
  height: auto;
  max-width: 100vw;
  overflow-x: hidden;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  outline: none;
}

body {
  margin: 0;
  padding: 0;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0C0C0C;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  transition: .3s;
  
  &:hover:not(.app-button) {
    color: $main-black;
    opacity: .7;
  }
}

button {
  outline: none;
  border: none;
}

p {
  margin: 0;
  font-size: 28px;
  line-height: 44px;
  font-weight: 400;
  color: $main-black;

  @include media-breakpoint-down(sm) {
    font-size: 16px;
    line-height: 24px;
  }
}

h1, h2, h3, h4, h5, h6 {
  color: $main-black;
  margin: 0;
}

h2 {
  font-size: 64px;
  color: $main-black;
  font-weight: 500;
  line-height: 76px;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.container {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding-left: 72px;
  padding-right: 72px;

  @include media-breakpoint-down(sm) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.photo-by {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #fff;
  position: absolute;
  bottom: 16px;
  left: 24px;
}

.app {
  &-popover {
    padding: 6px 14px;
    border-radius: 6px;
  
    span {
      font-weight: 500;
      font-size: 14px;
    }
  }

  &-wrap {
    &.hidden {
      opacity: 0;
      visibility: hidden;
      overflow: hidden;
    }
  }
}
