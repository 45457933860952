.app-contact {
    position: relative;
    display: flex;
    padding: 140px 0px;
    background-color: $white;

    @include media-breakpoint-down(sm) {
        padding: 72px 0px 48px;
    }
    
    .container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &-img {
        img {
            user-select: none;
            -webkit-user-select: none;
        }
        
        @include media-breakpoint-down(sm) {
            width: calc(100% - 72px);
        }

    }

    p {
        max-width: 720px;
        text-align: center;
        margin: 24px 0px 48px;
    }

    &-cta {
        display: flex;
        justify-content: center;
        align-items: center;

        @include media-breakpoint-down(sm) {
            flex-direction: column;
            width: 100%;
        }

        .app-button {
            margin: 0px 8px;

            @include media-breakpoint-down(sm) {
                width: 100%;
                margin-bottom: 20px;
            }
        }
    }
}