.app-privacy {
    position: relative;
    background-color: $white;
    padding-top: 200px;

    @include media-breakpoint-down(sm) {
        padding-top: 140px;
    }

    h1 {
        font-size: 64px;
        line-height: 89px;
        font-weight: 500;
        color: $main-black;
        text-align: center;

        @include media-breakpoint-down(sm) {
            font-size: 34px;
            line-height: 40px;
        }
    }

    
    &-content {
        margin: 160px 0px 140px;
        
        @include media-breakpoint-up(lg) {
            padding-left: 182px;
            padding-right: 182px;
        }

        @include media-breakpoint-down(lg) {
            padding-left: 72px;
            padding-right: 72px;
        }
        
        @include media-breakpoint-down(sm) {
            margin: 100px 0px 72px;
            padding-left: 20px;
            padding-right: 20px;
        }
        
        .container {            
            background-color: $gray-200;
            border-radius: 20px;
            padding-top: 72px;
            padding-bottom: 72px;

            @include media-breakpoint-down(sm) {
                padding: 40px 32px;
            }
        }

        a {
            color: $main-black;
            text-decoration: underline;
        }

        h2 {
            font-size: 44px;
            line-height: 56px;
            font-weight: 500;
            color: $main-black;
        
            @include media-breakpoint-down(sm) {
                font-size: 24px;
                line-height: 28px;
            }
        }

        h3 {
            font-size: 36px;
            line-height: 48px;
            font-weight: 500;
            color: $main-black;
            margin: 24px 0px;
            
            @include media-breakpoint-down(sm) {
                font-size: 18px;
                line-height: 24px;
                margin-bottom: 18px;
            }
        }

        p {
            margin-top: 40px;
            margin-bottom: 20px;
            font-size: 16px;
            line-height: 22px;
            font-weight: 400;

            @include media-breakpoint-down(sm) {
                margin-top: 24px;
                font-size: 13px;
                line-height: 19px;
            }
        }

        ul {
            list-style: disc;
            margin-left: 25px;
            
            li {
                margin-bottom: 20px;

                &:last-child {
                    margin-bottom: 0;
                }

                @include media-breakpoint-down(sm) {
                    margin-top: 24px;
                    font-size: 13px;
                    line-height: 19px;
                }
            }
        }
    }

    &-block {
        margin-top: 36px;

        ul {
            a {
                color: $main-black;
            }
        }
    }

    .app-contact {
        padding-top: 0;
    }
}