.app-hero {
    position: relative;
    background-color: $main-black;
    padding-top: 200px;

    @include media-breakpoint-down(sm) {
        padding-top: 140px;
    }

    &-img {
        margin-top: 128px;
        position: relative;
        // background-image: url(../../assets/img/hero.jpg);
        // min-height: 100vh; 
        // background-attachment: fixed;
        // background-position: center;
        // background-repeat: no-repeat;
        // background-size: cover;
        // transform: translateZ(0);
        // will-change: transform;

        // @include media-breakpoint-down(md) {
        //     background-attachment: initial;
        // }

        // @include media-breakpoint-down(sm) {
        //     min-height: 50vh;
        // }
        // background-position: -650px;
    }

    h1 {
        color: $white;
        font-size: 64px;
        font-weight: 500;
        text-align: center;
        margin-bottom: 24px;
        
        @include media-breakpoint-down(sm) {
            font-size: 34px;
            line-height: 40px;
            margin-bottom: 12px;
        }
    }

    &-link {
        display: none;

        @include media-breakpoint-down(sm) {
            display: flex;
            margin: 0 auto;
            justify-content: center;
            margin-top: 32px;
        }
    }

    p {
        font-size: 28px;
        text-align: center;
        margin-top: 24px;
        color: $white;
        max-width: 640px;
        margin: 0 auto;
        
        @include media-breakpoint-down(sm) {
            font-size: 16px;
            line-height: 24px;
            max-width: 304px;
        }
    }
}