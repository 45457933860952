.app-what-can {
    position: relative;
    padding: 140px 0px;
    background-color: $gray-300;

    @include media-breakpoint-down(sm) {
        padding: 72px 0px;
    }

    h2 {
        margin: 16px 0px 24px;

        @include media-breakpoint-down(sm) {
            font-size: 33px;
            line-height: 40px;
            margin-bottom: 48px;
        }
    }

    p {
        display: flex;

        span {
            margin-right: 16px;
        }
    }
}